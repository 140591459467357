

function Footar(){
    return(
        <>
       <div className="footar-main-row">
        <div className="footar-colum">
            
        
            <a href="https://react.dev/" target="blank" className="foo-text">Made in React.js By Pooja Mittal</a>
            
        </div>
       </div>
        </>
    )
}

export default Footar